import { useUserAddressCoordinates } from '@/core/hooks/useUserAddressCoordinates';
import { selectDelioBrand } from '@/core/selectors/selectDelioBrand';
import { useBrandsDashboardQuery } from '@/modules/location/queries/BrandsDashboard.common.generated';
import { publicEnv } from '@lib/env/public-env-vars';

/**
 * If the customer has an address attached to the cart it returns the store
 * for the addresses' coordinates.
 *
 * If the requests are not resolved it returns `null`.
 */
export const useStore = () => {
  const {
    coordinates,
    loading: shippingAddressLoading,
    error: shippingAddressError,
  } = useUserAddressCoordinates();

  const brandsDashboardResult = useBrandsDashboardQuery(
    coordinates
      ? {
          variables: { coordinates },
          fetchPolicy: 'cache-first',
        }
      : { skip: true }
  );

  const delioBrand = selectDelioBrand(
    brandsDashboardResult?.data?.brandsDashboard?.brands
  );

  const selectedDarkstore = delioBrand?.darkstore;

  const loading = shippingAddressLoading || brandsDashboardResult.loading;

  const error = shippingAddressError || brandsDashboardResult?.error;

  return {
    loading,
    store: selectedDarkstore && {
      darkstoreKey: selectedDarkstore.key,
      storeKey: publicEnv.NEXT_PUBLIC_CT_STORE_KEY,
      hasConcessionGranted: selectedDarkstore.hasConcessionGranted,
      address: selectedDarkstore.address,
      coordinates: coordinates ?? undefined,
      status: delioBrand?.status,
    },
    error,
  };
};
