import { ButtonHTMLAttributes, forwardRef, MouseEvent } from 'react';

import { cn } from '@/core/ui/utils';

import { ButtonContent } from './components/ButtonContent/ButtonContent';
import { createButtonStyles } from './helpers/createButtonStyles';
import { BaseButtonProps } from './types/Button.type';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  BaseButtonProps & {
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    type?: 'button' | 'submit';
  };

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      buttonStyle = 'primary',
      size = 'medium',
      type = 'button',
      leftIcon,
      rightIcon,
      disabled,
      onClick,
      children,
      icon,
      testId,
      className,
      isLoading,
      loadingIndicatorTitle,
      ...rest
    },
    ref
  ) => (
    <button
      ref={ref}
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={cn(
        createButtonStyles({ isIcon: !!icon, buttonStyle, size }),
        className
      )}
      disabled={disabled || isLoading}
      data-testid={testId}
      onClick={onClick}
      {...rest}
    >
      <ButtonContent
        icon={icon}
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        isLoading={isLoading}
        loadingIndicatorTitle={loadingIndicatorTitle}
      >
        {children}
      </ButtonContent>
    </button>
  )
);

Button.displayName = 'Button';
