import { FC } from '@/types/common';

export const FormattedNetContain: FC<{
  netContain?: number | null;
  containUnit?: string | null;
}> = ({ netContain, containUnit }) => {
  if (netContain === null || containUnit === null) {
    return null;
  }
  const formattedNetContain = [netContain, containUnit]
    .filter((value) => typeof value !== 'undefined')
    .join(' ');

  // Disabled due to pure `children` is not a ReactElement
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{formattedNetContain}</>;
};
