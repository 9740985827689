import { useShippingAddress } from '@/core/hooks/useShippingAddress';
import { selectCoordinatesFromAddress } from '@/modules/checkout/selectors/selectCoordinatesFromAddress';

export const useUserAddressCoordinates = () => {
  const shippingAddress = useShippingAddress();
  const coordinates = selectCoordinatesFromAddress(shippingAddress.data);

  return {
    loading: shippingAddress.loading,
    coordinates,
    error: shippingAddress.error,
  };
};
