import * as Apollo from '@apollo/client';
import { useMemo } from 'react';

import { AUTH_ROLE } from '@/modules/auth/contexts/AuthContext';
import { useSession } from '@/modules/auth/hooks/useSession';
import { useStore } from '@/modules/store/hooks/useStore';

import {
  CurrentCartQuery,
  CurrentCartQueryVariables,
  useCurrentCartQuery,
} from '../graphql/queries/CurrentCart/CurrentCart.delio.generated';

type BaseOptions = Apollo.QueryHookOptions<
  CurrentCartQuery,
  CurrentCartQueryVariables
>;

type UseCurrentCartVars = Omit<BaseOptions, 'skip'>;

export const useCurrentCart = (variables?: UseCurrentCartVars) => {
  const { store, loading: storeLoading } = useStore();

  const authRole = useSession('authRole');
  const createSessionState = useSession('createSessionState');

  const shouldQueryCurrentCart =
    store &&
    authRole !== AUTH_ROLE.UNAUTHENTICATED &&
    authRole !== AUTH_ROLE.NOT_CONFIRMED &&
    createSessionState.status !== 'loading' &&
    !storeLoading;

  const currentCartResult = useCurrentCartQuery(
    shouldQueryCurrentCart ? variables : { skip: true, ...variables }
  );

  return useMemo(
    () => ({
      ...currentCartResult,
      loading: currentCartResult.loading || storeLoading,
    }),
    [currentCartResult, storeLoading]
  );
};
