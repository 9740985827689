import { ReactElement } from 'react';

import { cn } from '@/core/ui/utils';
import { FCC } from '@/types/common';

export const Layout: FCC<{ navbar?: ReactElement; footer?: ReactElement }> = ({
  navbar,
  footer,
  children,
}) => (
  // ❗️ You would be better off not changing the following structure. Many
  // components may relay on it and it's very difficult to test CSS for
  // regression.
  //
  // ℹ️ If this setup does not fit your needs, you probably need to create a new
  // component for a your use case.
  <div className={cn('h-full', 'flex', 'flex-col')}>
    {navbar}
    <div
      className={cn(
        'grow',
        'flex',
        'flex-col',
        'justify-start',
        'items-stretch',
        cn('relative', 'z-0') // reset stacking context
      )}
    >
      {children}
    </div>
    {footer}
  </div>
);
