import { BrandsDashboardQuery } from '@/modules/location/queries/BrandsDashboard.common.generated';

type Brands = BrandsDashboardQuery['brandsDashboard']['brands'];
type Brand = Brands[number];
type Delio = Extract<Brand, { readonly __typename: 'DelioDashboardBrand' }>;

export const selectDelioBrand = (brands: Brands | undefined) =>
  brands?.find((i) => i?.__typename === 'DelioDashboardBrand') as
    | Delio
    | undefined;
