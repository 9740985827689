import { useCallback } from 'react';
import { useContextSelector } from 'use-context-selector';

import { AuthContext, AuthContextType } from '../contexts/AuthContext';

export const useSession = <Key extends keyof AuthContextType>(key: Key) =>
  useContextSelector(
    AuthContext,
    useCallback((context) => context[key], [key])
  );
