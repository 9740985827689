import { ShippingAddressInput } from '@/types/gqlCommon.delio.generated';

export const selectCoordinatesFromAddress = (
  shippingAddress?: ShippingAddressInput | null
) => {
  if (!shippingAddress?.lat || !shippingAddress?.long) return undefined;

  return {
    lat: shippingAddress.lat,
    long: shippingAddress.long,
  };
};
