import { useCurrentCartQuery } from '@/core/graphql/queries/CurrentCart/CurrentCart.delio.generated';
import { useCustomerShippingAddressQuery } from '@/core/graphql/queries/CustomerShippingAddress/CustomerShippingAddress.common.generated';
import { AUTH_ROLE } from '@/modules/auth/contexts/AuthContext';
import { useSession } from '@/modules/auth/hooks/useSession';
import { ShippingAddressInput } from '@/types/gqlCommon.delio.generated';

export const useShippingAddress = (): {
  data: ShippingAddressInput | null;
  loading: boolean;
  error: Error | null | undefined;
} => {
  const authRole = useSession('authRole');

  const skipCurrentCart = authRole !== AUTH_ROLE.ANONYMOUS_AUTHENTICATED;

  const skipCustomerAddress = authRole !== AUTH_ROLE.AUTHENTICATED;

  const {
    data: cartData,
    loading: cartLoading,
    error: cartError,
  } = useCurrentCartQuery({
    skip: skipCurrentCart,
  });

  const {
    data: customerAddressData,
    loading: customerAddressLoading,
    error: customerAddressError,
  } = useCustomerShippingAddressQuery({
    skip: skipCustomerAddress,
  });

  const shouldUseAddressFromCart =
    authRole === AUTH_ROLE.ANONYMOUS_AUTHENTICATED;

  const loading = cartLoading || customerAddressLoading;

  if (loading) {
    return {
      data: null,
      loading: true,
      error: null,
    };
  }

  if (shouldUseAddressFromCart) {
    const shippingAddress = cartData?.currentCart?.shippingAddress;
    if (shippingAddress) {
      return {
        data: shippingAddress,
        loading: false,
        error: null,
      };
    }
    return {
      data: null,
      loading: false,
      error: cartError,
    };
  }

  const customerShippingAddress =
    customerAddressData?.customerShippingAddress?.shippingAddress;
  if (customerShippingAddress) {
    return {
      data: customerShippingAddress,
      loading: false,
      error: null,
    };
  }

  return {
    data: null,
    loading: false,
    error: customerAddressError,
  };
};
